import React, {useState} from 'react'
import ButtonPrimary from '../../components/ButtonPrimary'
import NavbarDetail from '../../components/navbardetail/NavbarDetail'
import NextButton from '../../components/nextbutton/NextButton'
import PreviousButton from '../../components/previousButton/PreviousButton'
import './register-v2.css'
import Step1 from './step1/Step1'
import OtpV2 from '../otpv2/OtpV2'
import SuccessVerification from './success/SuccessVerification'
import { useHistory } from 'react-router-dom'
import { format } from 'date-fns'
import { ERROR, getJsonResponse, ggAlert, SUCCESS, url } from '../../service/Service'
import { Spin } from 'antd'
import moment from 'moment'

const RegisterV2 = () => {

    let history = useHistory()
    // console.log(history.location.state);
    const [step, setStep] = useState(1)
    const [previousActive, setPreviousActive] = useState(false)
    const [nextActive, setNextActive] = useState(true)
    const [isVerification, setIsVerification] = useState(false)
    const [isCheckedAgreement, setIsCheckedAgreement] = useState(false)
    const [loading, setLoading] = useState(false)

    const dataDefault = {
        date: moment(new Date()).subtract(18, 'year'),
        fullname: "",
        phoneNumber: "",
        email: "",
        password: "",
        confirmationPassword: "",
        province: "",
        city: "",
        gender: "-1",
        selectedItem: 0,
        selectedItemName: "",
        id_outlet: ""
    }

    const [data, setData] = useState(dataDefault)

    const setDataFromStep1 = (value) => {
        setData(value)
        // console.log(data)
    }

    async function register(number) {
        var formData = new FormData()
        formData.append("name", data.fullname)
        formData.append("email", data.email)
        formData.append("password", data.password)
        formData.append("phone", data.phoneNumber)
        formData.append("verification_by", "phone")
        formData.append("password_confirmation", data.confirmationPassword)
        formData.append("dob", data.date)
        formData.append("gender", data.gender == 1 && "Male" || data.gender === "1" && "Male" || data.gender == 0 && "Female" || data.gender === "0" && "Female" || "")
        formData.append("idprodukpilihan", data.selectedItem)
        formData.append("lokasi", data.city)
        formData.append("id_outlet", history.location.state.id)
        const requestOptions = {
            method: 'POST',
            body: formData
        }
        await fetch(url + "register", requestOptions)
            .then(response => {
                return getJsonResponse(response)
            })
            .then(json => {
                setStep(number)
                buttonState(number)
                console.log(json)
            })
            .catch(error => {
                ggAlert("Error", error, ERROR)
            })
    }

    async function onVerification(otp) {
        var formData = new FormData()
        formData.append("phone", data.phoneNumber)
        formData.append("otp_code", otp)
        const requestOptions = {
            method: 'POST',
            body: formData
        }
        await fetch(url + "otp/verify", requestOptions)
            .then(response => {
                return getJsonResponse(response)
            })
            .then(json => {
                setIsVerification(true)
                console.log(json)
            })
            .catch(error => {
                ggAlert("Error", error, ERROR)
            })
    }

    const clicked = (number) => {
        if (step === number) return

        if (step == 1) {
            if (!isValidateInput()) {
                ggAlert("Error", "Mohon lengkapi data anda!", ERROR)
                return
            }

            if (!isPasswordMatch()) {
                ggAlert("Error", "Password dan Konfirmasi Password tidak sama!", ERROR)
                return
            }
            register(number)
        } else if (step == 2) {
            setData(dataDefault)
            setStep(number)
            buttonState(number)
        }
    }

    function isPasswordMatch() {
        return data.password === data.confirmationPassword
    }

    function isValidateInput() {
        if (data.fullname === "") {
            return false
        }

        if (data.date === "") {
            return false
        }

        if (data.gender === "-1") {
            return false
        }

        if (data.phoneNumber === "") {
            return false
        }

        if (data.password === "") {
            return false
        }

        if (data.confirmationPassword === "") {
            return false
        }

        if (data.province === "") {
            console.log("province")
            return false
        }

        if (data.city === "") {
            console.log("city")
            return false
        }

        if (data.selectedItem == 0) {
            return false
        }
        // if (data.id_outlet == "") {
        //     return false
        // }

        return true
    }

    const buttonState = (number) => {
        setPreviousActive(number !== 1)
        setNextActive(number !== 2)
    }

    const onCheckedAgreement = () => {
        setIsCheckedAgreement(!isCheckedAgreement)
    }

    const onRegisterButtonClicked = () => {
        onLogin()
    }

    async function onResendOTP() {
        var formData = new FormData()
        formData.append("phone", data.phoneNumber)
        const requestOptions = {
            method: 'POST',
            body: formData
        }
        await fetch(url + "sendOTP", requestOptions)
            .then(response => {
                return getJsonResponse(response)
            })
            .then(json => {
                ggAlert("Success", json['message'], SUCCESS)
                console.log(json)
            })
            .catch(error => {
                ggAlert("Error", error, ERROR)
            })
    }

    async function onLogin() {
        const endpoint = "login"
        
        const body = {
            emailOrPhone: data.phoneNumber,
            password: data.password
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body)
        }

        setLoading(true)
        await fetch(url + endpoint, requestOptions)
            .then(response => {
                return getJsonResponse(response)
            })
            .then(json => {
                localStorage.setItem('accessToken', json['data']['access_token'])
                localStorage.setItem('userId', json['data']['id'])
                localStorage.setItem('role', json['data']['role'])
                localStorage.setItem("phone", json['data']['phone'])
                localStorage.setItem("lokasiId", json['data']['lokasi'])
                localStorage.setItem("kota", json['data']['city'])
                localStorage.setItem("produkPilihan", json['produkpilihan'])
                window.location.href="/home"
            })
            .catch(error => {
                ggAlert("Error", error, ERROR)
            })
        setLoading(false)
    }
    
    return (
        <div className="registerContainer">
            <NavbarDetail
                title="DAFTAR"/>
            <div className="registerContentContainer">
                {!isVerification && step == 1 && <Step1 onDataChanged={setDataFromStep1} data={data}/> ||
                 !isVerification && step == 2 && <OtpV2 onVerification={onVerification} onResendOTP={onResendOTP} phoneNumber={data.phoneNumber}/> ||
                 isVerification && <SuccessVerification phoneNumber={data.phoneNumber}/>}
                <div className="registerBottomContainer">
                    <div className="registerNavigationContiner">
                        <PreviousButton onClick={clicked} active={previousActive}/>
                        <p className="registerNavigationText">{step}/2</p>
                        <NextButton onClick={clicked} active={nextActive}/>
                    </div>
                    <div className="registerAgreementContainer">
                        <input type="checkbox" onClick={() => onCheckedAgreement()}/>
                        <p className="registerAgreementText">
                            Ya, saya perokok berumur 18 tahun ke atas, dan menyetujui <a className="registerAgreementLink" href="/term-agreement">Syarat &amp; Ketentuan</a> dan <a className="registerAgreementLink" href="/privacy-policy">Kebijakan Privasi</a> yang berlaku.
                            {/* Dengan mencentang kotak ini, saya setuju dengan syarat dan ketentuan yang telah ditentukan oleh pihak Arena Madya Wisesa */}
                        </p>
                    </div>
                    {
                        loading && <Spin/>
                        ||
                        <ButtonPrimary
                            active={isCheckedAgreement}
                            text="Daftar &amp; Masuk"
                            onClick={() => isCheckedAgreement && isVerification && onRegisterButtonClicked()}/>
                    }
                </div>
            </div>
        </div>
    )
}

export default RegisterV2
