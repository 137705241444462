/* eslint-disable no-mixed-operators */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import './buy-package.css'
import NavbarDetail from '../../components/navbardetail/NavbarDetail'
import ButtonPrimary from '../../components/ButtonPrimary'
import { BsCheckCircle } from 'react-icons/bs'
import SuccessLayout from '../../components/successlayout/SuccessLayout'
import { useHistory, useParams } from 'react-router-dom'
import ButtonSecondary from '../../components/ButtonSecondary'
import InputPrimary from '../../components/input/InputPrimary'
import { AiFillMinusCircle, AiFillPlusCircle, AiOutlineClose } from 'react-icons/ai'
import BuyPackageMenu from '../../components/buypackage/menu/BuyPackageMenu'
import { ERROR, getJsonResponse, ggAlert, headerBearer, headerNoUrlEncodedBearer, INFO, url } from '../../service/Service'
import BuyConfirmationModal from '../../components/buyconfirmationmodal/BuyConfirmationModal'

const BuyPackage = (props) => {

    const {state} = props.location
    const [itemsReguler, setItemsReguler] = useState([])

    const [selectedData, setSelectedData] = useState({})
    const [selected, setSelected] = useState(false)
    const [isConfirmed, setConfirmation] = useState(false)
    const [title, setTitle] =useState("BELI PAKET")
    const [buttonText, setButtonText] =useState("Konfirmasi Pilihan")
    const [number, setNumber] = useState(0)
    const [packageType, setPackageType] = useState("")
    const [itemType, setItemType] = useState(0)
    const [itemPaket, setItemPaket] = useState([])
    const [itemLoyalty, setItemLoyalty] = useState([])
    const [itemLoyalty2, setItemLoyalty2] = useState([])
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [confirmationData, setConfirmationData] = useState({})
    const LOYALTY = "loyalty"
    const CPO = "cpo"

    const setMinus = () => {
        let value = 0
        if (number - 1 < 0) {
            value = 0
        } else {
            value = number - 1
        }
        setNumber(value)
    }

    const setPlus = () => {
        let value = number + 1
        // let value = 0
        // if (number + 1 > parseInt(selectedData.alokasi_item)) {
        //     value = parseInt(selectedData.alokasi_item)
        // } else {
        //     value = number + 1
        // }
        setNumber(value)
    }

    const history = useHistory()

    const [isBottomSheetShown, setBottomShetShown] = useState(false)

    const onConfirm = () => {
        if (isConfirmed) {
            history.push("/home")
            return
        }
        if (selected) {
            setBottomShetShown(true)
        } else {
            ggAlert("Info", "Mohon untuk memilih paket!", INFO)
        }
    }

    async function getPackage() {
        await fetch(url + "allprogram/" + state.id, { headers: headerBearer })
            .then(response => {
                return getJsonResponse(response)
            })
            .then(json => {
                setItemPaket([])
                json['data']['cpo'].map(function(d, index){
                    var data = {
                        id: d.id, name: d.nama_paket, point: d.poin && d.poin || "0", alokasi_item: d.alokasi_item, idProgram: d.assign_program_id
                    }
                    setItemPaket(arr => [...arr, data])
                })

                setItemLoyalty([])
                json['data']['loyalty'].map(function(d, index){
                    var data = {
                        id: d.id, name: d.nama_paket, point: d.poin && d.poin || "0", idProgram: d.id_program
                    }
                    setItemLoyalty(arr => [...arr, data])
                })

                setItemLoyalty2([])
                json['data']['loyalty_berjenjang'].map(function(d, index){
                    var data = {
                        name: d.nama_paket, point: d.poin && d.poin || "0"
                    }
                    setItemLoyalty2(arr => [...arr, data])
                })
                // console.log(json)
            })
            .catch(error => {
                console.log(error)
            })
    }

    async function buy() {
        var formData = new FormData()

        var endpoint = "order/paketcpo"

        if (packageType == LOYALTY) {
            endpoint = "order/paketloyalty"
        }

        formData.append("idpaket", selectedData.id)
        formData.append("qty", number)
        formData.append("id_outlet", state.id)
        formData.append("idprogram", selectedData.idProgram)
        if (state.type === 'useroutlet') {
            formData.append("id_konsumen", state.userId)
            endpoint = "transactionmerchant/orderpaketcpo"

            if (packageType == LOYALTY) {
                endpoint = "transactionmerchant/orderpaketloyalty"
            }
        }
        const requestOptions = {
            method: 'POST',
            body: formData,
            headers: headerNoUrlEncodedBearer
        }


        await fetch(url + endpoint, requestOptions)
            .then(response => {
                return getJsonResponse(response)
            })
            .then(json => {
                setBottomShetShown(false)
                setTitle("PEMBELIAN SELESAI")
                setButtonText("Kembali Ke Beranda")
                setConfirmation(true)
                console.log(json)
            })
            .catch(error => {
                ggAlert("Notifikasi", error, ERROR)
            })
    }

    useEffect(() => {
        getPackage()
    }, [])
    
    const bottomSheetClose = () => {
        setBottomShetShown(false)
    }

    const bottomSheetConfirm = () => {
        if (number == 0) {
            ggAlert("Error", "Tidak boleh 0", ERROR)
            return
        }
        setConfirmationData({packageName: selectedData.name, number: number})
        setShowConfirmation(true)
    }

    const onCancelConfirmation = () => {
        setShowConfirmation(false)
    }

    const onOkConfirmation = () => {
        setShowConfirmation(false)
        buy()
    }

    const onSelectItem = (data, type) => {
        // console.log(data);
        setSelectedData(data)
        setPackageType(type)
        setSelected(true)
    }

    const onChangeType = (index) => {
        setItemType(index)
    }

    return (
        <div className="buy-package-container">
            <NavbarDetail
                title={title}/>

            <div className="buy-package-container-menu">
                {
                    !isConfirmed && <BuyPackageMenu onChangeType={onChangeType}/>
                }
            </div>

            <div className="buy-package-container-2">
                <div className="buy-package-location-container">
                    <p className="buy-package-location-name">{state && state.name}</p>
                    <p className="buy-package-location-address">{state && state.address}</p>
                    {
                        isConfirmed && 
                        <div className="buy-package-point-selected-container">
                            <div className="buy-package-point-selected-container-2">
                                <p className="buy-package-point-selected-name">{selectedData.name}</p>
                                <p className="buy-package-point-selected-number">{number} pcs</p>
                            </div>
                            <p className="buy-package-point-selected">{packageType !== "cpo" && "+" + selectedData.point + " poin"}</p>
                        </div>
                    }
                </div>

                {
                    isConfirmed && 
                    <SuccessLayout
                        title="Terima Kasih!"
                        message="Dimohon menunggu hingga merchant selesai memproses pembelian anda."/>
                    ||
                    <>
                    {
                        itemPaket.length > 0 && itemType == 0 &&
                        <>
                            <p className="buy-package-label">Reguler</p>
                            {
                                itemPaket.map(function(item, i) {
                                    return <>
                                        <div className="buy-package-point-container"
                                            key={i}>
                                            <div className="buy-package-point-container-2">
                                                <p className="buy-package-point-label">{item.name}</p>
                                            </div>
                                            <input type="radio" name="point" onClick={() => onSelectItem(item, CPO)}/>
                                        </div>
                                        <div className="buy-package-line"/>  
                                    </>
                                })
                            }
                        </>
                        ||
                        itemPaket.length > 0 && itemType == 1 &&
                        <>
                            {
                                itemPaket.map(function(item, i) {
                                    return <>
                                        <div className="buy-package-point-container"
                                            key={i}>
                                            <div className="buy-package-point-container-2">
                                                <p className="buy-package-point-label">{item.name}</p>
                                            </div>
                                            <input type="radio" name="point" onClick={() => onSelectItem(item, CPO)}/>
                                        </div>
                                        <div className="buy-package-line"/>  
                                    </>
                                })
                            }
                        </>
                    }
                    
                    {
                        itemLoyalty.length > 0 && itemType == 0 &&
                        <>
                            <p className="buy-package-label">Loyalty</p>
                            {
                                itemLoyalty.map(function(item, i) {
                                    return <>
                                        <div className="buy-package-point-container"
                                            key={i}>
                                            <div className="buy-package-point-container-2">
                                                <p className="buy-package-point-label">{item.name}</p>
                                                <p className="buy-package-point">+ {item.point} poin</p>
                                            </div>
                                            <input type="radio" name="point" onClick={() => onSelectItem(item, LOYALTY)}/>
                                        </div>
                                        <div className="buy-package-line"/>  
                                    </>
                                })
                            }
                        </>
                        ||
                        itemLoyalty.length > 0 && itemType == 2 &&
                        <>
                            {
                                itemLoyalty.map(function(item, i) {
                                    return <>
                                        <div className="buy-package-point-container"
                                            key={i}>
                                            <div className="buy-package-point-container-2">
                                                <p className="buy-package-point-label">{item.name}</p>
                                                <p className="buy-package-point">+ {item.point} poin</p>
                                            </div>
                                            <input type="radio" name="point" onClick={() => onSelectItem(item, LOYALTY)}/>
                                        </div>
                                        <div className="buy-package-line"/>  
                                    </>
                                })
                            }
                        </>
                    }

                    {
                        itemLoyalty2.length > 0 && itemType == 0 &&
                        <>
                            <p className="buy-package-label">Loyalty Berjenjang</p>
                            {
                                itemLoyalty2.map(function(item, i) {
                                    return <>
                                        <div className="buy-package-point-container"
                                            key={i}>
                                            <div className="buy-package-point-container-2">
                                                <p className="buy-package-point-label">{item.name}</p>
                                                <p className="buy-package-point">+ {item.point} poin</p>
                                            </div>
                                            <input type="radio" name="point" onClick={() => onSelectItem(item, LOYALTY)}/>
                                        </div>
                                        <div className="buy-package-line"/>  
                                    </>
                                })
                            }
                        </>
                        ||
                        itemLoyalty2.length > 0 && itemType == 3 &&
                        <>
                            {
                                itemLoyalty2.map(function(item, i) {
                                    return <>
                                        <div className="buy-package-point-container"
                                            key={i}>
                                            <div className="buy-package-point-container-2">
                                                <p className="buy-package-point-label">{item.name}</p>
                                                <p className="buy-package-point">+ {item.point} poin</p>
                                            </div>
                                            <input type="radio" name="point" onClick={() => onSelectItem(item, LOYALTY)}/>
                                        </div>
                                        <div className="buy-package-line"/>  
                                    </>
                                })
                            }
                        </>
                    }
                    </>
                }
            </div>

            <div className="buy-package-button-container">
                <ButtonPrimary
                    text={buttonText}
                    onClick={() => onConfirm()}/>
            </div>

            <div className={isBottomSheetShown && "gg-bottom-sheet-container" || "gg-bottom-sheet-container-hide"}>
                <div className="gg-bottom-sheet-header">
                    <AiOutlineClose className="gg-bottom-sheet-header-close-icon"
                        onClick={() => bottomSheetClose()}/>
                    <p className="gg-bottom-sheet-header-label">JUMLAH PAKET</p>
                </div>

                <div className="gg-line"/>

                <div className="gg-bottom-sheet-content-vertical">
                    {/* <p className="buy-package-bottom-sheet-label">Max {selectedData.alokasi_item} pcs</p> */}
                    <p className="buy-package-bottom-sheet-label"></p>
                    <div className="gg-bottom-sheet-content">
                        <AiFillMinusCircle className="buy-package-bottom-sheet-icon"
                            onClick={() => setMinus()}/>
                        <p className="buy-package-bottom-sheet-value">{number}</p>
                        <AiFillPlusCircle className="buy-package-bottom-sheet-icon"
                            onClick={() => setPlus()}/>
                    </div>
                </div>

                <div className="gg-bottom-sheet-footer">
                    <ButtonSecondary
                        text="Batal"
                        onClick={() => bottomSheetClose()}/>
                    <div className="gg-bottom-sheet-footer-separator"/>
                    <ButtonPrimary
                        text="Lanjut"
                        onClick={() => bottomSheetConfirm()}/>
                </div>
            </div>
            <BuyConfirmationModal
                isShowModal={showConfirmation}
                onOk={() => onOkConfirmation()}
                onCancel={() =>onCancelConfirmation()}
                data={confirmationData}/>
        </div>
    )
}

export default BuyPackage
