/* eslint-disable jsx-a11y/anchor-is-valid */
import { FaArrowLeft } from 'react-icons/fa'
import { BiScan } from 'react-icons/bi'
import { useHistory } from "react-router-dom"
import { isSafari } from 'react-device-detect'
import "./navbar-detail.css"

const NavbarDetail = ({ title, subTitle, isShownScanIcon, useBackButton, shadow }) => {

    const history = useHistory()

    const onBackClicked = () => {
        if (isSafari) {
            history.go(-1)
        } else {
            history.goBack()
        }
    }

    return (
        <div className={shadow && "navbarDetailContainer" || "navbarDetailNoShadowContainer"}>
            <div className="navbarDetailTitleContainer">
                <p className="navbarDetailTitle">{title}</p>
                { subTitle && <p className="navbarDetailSubTitle">{subTitle}</p>}
            </div>
            { useBackButton && 
                <a style={{background: 'none', zIndex: 10}} href='#'>
                    <FaArrowLeft className="navbarDetailIcon"
                        onClick={() => onBackClicked()}/>
                </a>
            }
            {isShownScanIcon &&
                <>
                    <div style={{width: '100%', background: 'none', height: 50}}/>
                    <a style={{background: 'none', zIndex: 10}} href='#'>
                        <BiScan className="navbarDetailIconScan"
                            onClick={() => onBackClicked()}/>
                    </a>
                </>
            }
        </div>
    )
}

NavbarDetail.defaultProps = {
    title: '',
    isShownScanIcon: false,
    useBackButton: true,
    shadow: true
}

export default NavbarDetail
