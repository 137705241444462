import { Spin } from 'antd'
import React, { useState } from 'react'
import { FaStar } from 'react-icons/fa'
import ButtonPrimary from '../../components/ButtonPrimary'
import NavbarDetail from '../../components/navbardetail/NavbarDetail'
import ReservationConfirmationBottom from '../../components/reservationconfirmationbottom/ReservationConfirmationBottom'
import CancelLayout from '../../components/successlayout/CancelLayout'
import SuccessLayout from '../../components/successlayout/SuccessLayout'
import UserCard from '../../components/usercard/UserCard'
import { ERROR, getJsonResponse, ggAlert, headerNoUrlEncodedBearer, url } from '../../service/Service'
import './change-point-confirmation.css'

const ChangePointConfirmation = (props) => {

    const {state} = props.location
    const [loading, setLoading] = useState(false)
    const [isConfirmed, setConfirm] = useState(false)
    const [message, setMessage] = useState("")
    const [statusAction, setStatusAction] = useState(0)

    async function confirm(status) {
        setStatusAction(status)
        setLoading(true)
        var formData = new FormData()
        formData.append("id_transaction_reward", state.data.idtransactionrewards)
        formData.append("tipe", state.data.tipe)
        formData.append("status", status)

        const requestOptions = {
            method: 'POST',
            body: formData,
            headers: headerNoUrlEncodedBearer
        }

        await fetch(url + "konfirmasitukarpointrewards/merchant", requestOptions)
            .then(response => {
                return getJsonResponse(response)
            })
            .then(json => {
                setConfirm(true)
                setMessage(json['message'])
            })
            .catch(error => {
                ggAlert("Notifikasi", error, ERROR)
            })
        setLoading(false)
    }

    return (
        <div className="gg-parent-container">
            <NavbarDetail
                title="RINCIAN PENUKARAN"/>
            
            <div className={state && state.isActive && "gg-content-scroll-container" || "gg-content-no-top-padding-container"}>
                {
                    isConfirmed && 
                    <div className="change-point-confirmation-center">                            
                        {
                            statusAction == 1
                            &&
                            <SuccessLayout
                                title="Penukaran Terverifikasi!"
                                message={message}/>
                            ||
                            <CancelLayout
                                title="Penukaran Ditolak!"
                                message={message}/>
                        }
                    </div>
                    ||
                    <div className="change-point-confirmation-content-container">
                        <UserCard
                            data={state && {consumen: state.data.name, phone: state.data.phone}}/>

                        <p className="reservation-confirmation-label">Penukaran Poin</p>
                        <p className="change-point-confirmation-name-label">{state && state.data.item}</p>
                        {
                            state && state.isActive &&
                            <div className="change-point-confirmation-item-container">
                                <img src={state && state.data.image || "../../logo192.png"} className="change-point-confirmation-image-2"/>
                                <div className="change-point-confirmation-item-container-2">
                                    <p className="change-point-confirmation-expire-label">Kadaluwarsa Pukul</p>
                                    <p className="change-point-confirmation-expire">{state && state.data && state.data.expire && state.data.expire}</p>
                                </div>
                            </div>
                            ||
                            <img src={state && state.data.image || "../../logo192.png"} className="change-point-confirmation-image"/>
                        }
                    </div>
                }
            </div>

            {
                state && state.isActive &&
                <div className="gg-bottom-container">
                    {
                        loading && <Spin/>
                        ||
                        !isConfirmed &&
                        <ReservationConfirmationBottom
                            onConfirmation={() => confirm(1)}
                            onCancel={() => confirm(2)}/>
                        ||
                        <ButtonPrimary
                            text="Kembali ke Inbox"
                            onClick={() => window.location.href = "/inbox"}/>
                    }
                    
                </div>
            }

        </div>
    )
}

export default ChangePointConfirmation
