import React, { useEffect, useState } from "react";
import NavbarDetail from "../../components/navbardetail/NavbarDetail";
import "./scan-qr-register.css";
import QrReader from "react-qr-reader";
import { useHistory } from "react-router-dom";
import ButtonPrimary from "../../components/ButtonPrimary";
import { useParams } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import ButtonSecondary from "../../components/ButtonSecondary";
import InputPrimary from "../../components/input/InputPrimary";
import {
  ERROR,
  getJsonResponse,
  ggAlert,
  headerBearer,
  headerNoUrlEncodedBearer,
  url,
} from "../../service/Service";
import { Spin } from "antd";
import InputAutoSearch from "../../components/input/InputAutoSearch";

const ScanQRRegister = (props) => {
  const { type } = useParams();
  const { state } = props.location;

  const history = useHistory();
  const [inputManual, setInputManual] = useState({});
  const [input, setInput] = useState("");
  const [isBottomSheetShown, setBottomShetShown] = useState(false);
  const [isScanEnabled, setScanEnabled] = useState(true);
  var outlet = [];
  var outletMap = new Map();
  var outlets = [];
  async function fetchOutlet() {
    await fetch(url + "all_outlet")
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        json.data.map(function (d, i) {
          outlet[i] = d.outlet_code;
          if (!outletMap.has(d.outlet_name)) {
            outletMap.set(d.outlet_name, d.id, d.outlet_code);
          }
          outlets.push({
            id: d.id,
            name: d.outlet_name,
            code: d.outlet_code,
          });
        });
      })
      .catch((error) => console.log(error));
  }
  useEffect(() => {
    fetchOutlet();
  });
  async function checkQRCode(data) {
    if (data === null || data === "") return;
    setScanEnabled(false);
    var found = outlets.find(function (element) {
      // console.log(element);
      return element.code === data;
    });
    //   console.log(found);
    history.push({
      pathname: "/register-step2",
      state: found,
    });
  }

  const handleScan = (data) => {
    if (!isScanEnabled) return;
    checkQRCode(data);
  };

  const inputManualConfirm = async() => {
    if (input === "") {
      ggAlert("Error", "Inputan tidak boleh kosong", "error");
    } else {
      try {
        const response = await fetch(url + `outlet_search/${input}`, {
          method: "GET", // Adjust the HTTP method as needed
        });
        if (response.status === 422) {
          const data = await response.json();
          ggAlert("Error", data.message, "error");
        } else {
          const data = await response.json();
          // console.log(data);
          history.push({
            pathname: "/register-step2",
            state: data.data,
          });
        }
  
        // console.log(response);
      } catch (error) {
        console.error(error);
      }
    }

  };

  const handleError = (err) => {
    console.error(err);
  };
  const previewStyle = {
    height: "100%",
    width: "100%",
    background: "none",
  };

  const bottomSheetClose = () => {
    setBottomShetShown(false);
  };

  const bottomSheetShown = () => {
    setBottomShetShown(true);
  };
  const onSelectedOtlet = (outlet) => {
    // console.log(outlet);
    var found = outlets.find(function (element) {
      // console.log(element);
      return element.code === outlet;
    });
    //   console.log(found);
    setInputManual(found);
  };

  async function searchOutlet(search) {
    try {
      const response = await fetch(url + `outlet_search/${search}`, {
        method: "GET", // Adjust the HTTP method as needed
      });
      if (response.status === 422) {
        const data = await response.json();
        ggAlert("Error", data.message, "error");
      } else {
        const data = await response.json();
        history.push({
          pathname: "/register-step2",
          state: data.data,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }
  return (
    <div className="gg-parent-container">
      <NavbarDetail title="SCAN QR OUTLET" />

      <div className="gg-content-no-top-padding-container">
        {(isScanEnabled && (
          <QrReader
            delay={300}
            style={previewStyle}
            onError={handleError}
            onScan={handleScan}
          />
        )) || <Spin />}
      </div>

      <div className="gg-bottom-container">
        <ButtonPrimary
          text={"Input Kode Outlet"}
          onClick={() => bottomSheetShown()}
        />
      </div>

      <div
        className={
          (isBottomSheetShown && "gg-bottom-sheet-container") ||
          "gg-bottom-sheet-container-hide"
        }
      >
        <div className="gg-bottom-sheet-header">
          <AiOutlineClose
            className="gg-bottom-sheet-header-close-icon"
            onClick={() => bottomSheetClose()}
          />
          <p className="gg-bottom-sheet-header-label">{"INPUT OUTLET"}</p>
        </div>

        <div className="gg-line" />

        <div className="gg-bottom-sheet-content">
          <input
            className="navbarSearchInput"
            placeholder={"Cari Outlet ..."}
            onChange={(e)=> setInput(e.target.value)}
            onKeyPress={(e) =>
              e.key === "Enter" && searchOutlet(e.target.value)
            }
          />
          {/* <InputAutoSearch
                    label="Outlet From"
                    placeholder="Cari Outlet ..."
                    suggestions={outlet}
                    onSelected={(data) => onSelectedOtlet(data)}/> */}
        </div>

        <div className="gg-bottom-sheet-footer">
          {(isScanEnabled && (
            <>
              <ButtonSecondary
                text="Batal"
                onClick={() => bottomSheetClose()}
              />
              <div className="gg-bottom-sheet-footer-separator" />
              <ButtonPrimary
                text="Konfirmasi"
                onClick={() => inputManualConfirm()}
              />
            </>
          )) || <Spin />}
        </div>
      </div>
    </div>
  );
};

export default ScanQRRegister;
