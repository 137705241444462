import TextArea from 'rc-textarea'
import React, { useRef, useState } from 'react'
import { FaPlus } from 'react-icons/fa'
import ButtonPrimary from '../../components/ButtonPrimary'
import GGDatePicker from '../../components/datepicker/GGDatePicker'
import InputPrimary from '../../components/input/InputPrimary'
import NavbarDetail from '../../components/navbardetail/NavbarDetail'
import SuccessLayout from '../../components/successlayout/SuccessLayout'
import './promo-create.css'
import { useHistory } from 'react-router-dom'
import { isSafari } from 'react-device-detect'
import { ERROR, getJsonResponse, ggAlert, headerNoUrlEncodedBearer, url } from '../../service/Service'
import { Spin } from 'antd'
import moment from 'moment'

const PromoCreate = () => { 

    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [start, setStart] = useState(new Date())
    const [end, setEnd] = useState(new Date())
    const [description, setDescription] = useState("")
    const [title, setTitle] = useState("")

    const onBackClicked = () => {
        if (isSafari) {
            history.go(-1)
        } else {
            history.goBack()
        }
    }

    const uploadImage = useRef(null)
    const [isCreated, setCreated] = useState(false)

    const onButtonClicked = () => {
        if (isCreated) {
            onBackClicked()
            return
        }
        uploadPromo()
    }

    const [ image, setImage ] = useState("")

    const onImageSelected = (event) => {
        setImage({
            src: URL.createObjectURL(event.target.files[0]),
            file: event.target.files[0]
        })
    }

    async function uploadPromo() {
        setLoading(true)

        var formData = new FormData()
        formData.append("title", title)
        formData.append("image", image.file)
        formData.append("deskripsi", description)
        formData.append("tanggal_mulai", start)
        formData.append("tanggal_akhir", end)
        formData.append("idoutlet", JSON.parse(localStorage.getItem("outlet")).id)

        const requestOptions = {
            method: 'POST',
            body: formData,
            headers: headerNoUrlEncodedBearer
        }

        await fetch(url + "horeca/promomerchant", requestOptions)
            .then(response => {
                return getJsonResponse(response)
            })
            .then(json => {
                setCreated(!isCreated)
            })
            .catch(error => {
                ggAlert("Error", error, ERROR)
            })
        setLoading(false)
    }

    const setDateStart = (value) => {
        console.log(value);
        setStart(moment(value).format("yyyy-MM-DD"))
    }

    const setDateEnd = (value) => {
        setEnd(moment(value).format("yyyy-MM-DD"))
    }

    return (
        <div className="gg-parent-container">
            <NavbarDetail
                title="TAMBAH PROMO"/>

            <div className="gg-content-with-bottom-container">
                {
                    isCreated && 
                    <SuccessLayout 
                        title="Promo Diajukan!"
                        message="Kami akan menginformasikan jika pengajuan Anda telah disetujui."/>
                    ||
                    <div className="promo-create-content-container">
                        <div className="promo-create-photo-container" onClick={() => uploadImage.current.click()}>
                            <img src={image.src} className="promo-create-photo"/>
                            <input type="file" ref={uploadImage} name="file" style={{display: 'none'}} onChange={onImageSelected}/>
                            <FaPlus className="promo-create-add-icon"/>
                            <p className="promo-create-add-label">Tambah gambar promo</p>
                        </div>

                        <InputPrimary
                            type="text"
                            placeholder="Judul Promo"
                            onChange={(e) => setTitle(e)}/>

                        <div className="promo-create-input-container">
                            <GGDatePicker
                                label="Tanggal Mulai"
                                placeholder="Judul Promo"
                                onChange={setDateStart}/>
                            <GGDatePicker
                                label="Tanggal Akhir"
                                placeholder="Judul Promo"
                                onChange={setDateEnd}/>
                        </div>

                        <div className="promo-create-desc-container">
                            <p className="promo-create-desc-label">Deskripsi</p>
                            <textarea className="promo-create-desc"
                                onChange={(event) => setDescription(event.target.value)}/>
                        </div>
                    </div>
                }
            </div>

            <div className="gg-bottom-container">
                {
                    loading && <Spin/>
                    ||
                    <ButtonPrimary
                        text={isCreated && "Kembali Ke Manajemen Promo" ||"Ajukan Promo"}
                        onClick={() => onButtonClicked()}/>
                }
            </div>
        </div>
    )
}

export default PromoCreate
