import { Spin, Tabs } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import { FaPlus } from 'react-icons/fa';
import EmptyPage from '../../components/emptypage/EmptyPage';
import NavbarDetail from '../../components/navbardetail/NavbarDetail'
import { ERROR, getJsonResponse, ggAlert, headerBearerJsonRequest, headerNoUrlEncodedBearer, INFO, requestOptions, SUCCESS, url } from '../../service/Service';
import GalleryVenue from './gallery/GalleryVenue';
import MenuVenue from './menu/MenuVenue';
import Schedule from './schedule/Schedule';
import moment from 'moment'
import { format } from "date-fns";

const VenueConfig = () => {

    const { TabPane } = Tabs;

    const [gallery, setGallery] = useState([])
    const [menu, setMenu] = useState([])
    const [schedule, setSchedule] = useState([])
    const [menuLoading, setMenuLoading] = useState(false)
    const [galleryLoading, setGalleryLoading] = useState(false)
    const [scheduleLoading, setScheduleLoading] = useState(false)
    const [searchPlaceHolder, setSearchPlaceholder] = useState("Cari Galeri")
    var scheduleOpenChange = new Map()
    var scheduleCloseChange = new Map()

    function callback(key) {
        // console.log(key);
        switch(key) {
            case "1":
                setSearchPlaceholder("Cari Galeri")
                break;
            case "2":
                setSearchPlaceholder("Cari Menu")
                break;
            case "3":
                setSearchPlaceholder("Cari Jadwal")
                break;
            default:
                setSearchPlaceholder("Cari Galeri")
                break;        
        }
    }

    async function getGallery() {
        setGalleryLoading(true)
        var outlet = JSON.parse(localStorage.getItem("outlet"))
        await fetch(url + "horeca/venue/galleryhoreca/" + outlet.id, {headers: headerNoUrlEncodedBearer})
            .then(response => getJsonResponse(response))
            .then(json => {
                setGallery(json['data']['data'])
            })
            .catch(error => console.log(error))
        setGalleryLoading(false)
    }

    async function getMenu() {
        setMenuLoading(true)
        var outlet = JSON.parse(localStorage.getItem("outlet"))
        await fetch(url + "horeca/venue/menuhoreca/" + outlet.id, {headers: headerNoUrlEncodedBearer})
            .then(response => getJsonResponse(response))
            .then(json => {
                setMenu(json['data']['data'])
            })
            .catch(error => console.log(error))
        setMenuLoading(false)
    }

    useEffect(() => {
        getGallery()
        getMenu()
        getSchdule()
    }, [])

    const uploadGallery = useRef(null)
    const onGallerySelected = (event) => {
        console.log(event.target.files[0])
        let text = "Apakah anda yakin ingin menambahkan Galeri?"
        if (window.confirm(text) == true) {
            saveGallery(event.target.files[0])
        } else {
            uploadGallery.current.value = ""
        }
    }

    async function saveGallery(image) {
        setGalleryLoading(true)
        var outlet = JSON.parse(localStorage.getItem("outlet"))

        var formData = new FormData()
        
        formData.append("image", image)
        formData.append("idoutlet", outlet.id)

        const requestOptions = {
            method: 'POST',
            body: formData,
            headers: headerNoUrlEncodedBearer
        }
        await fetch(url + "horeca/venue/galleryhoreca/store", requestOptions)
            .then(response => getJsonResponse(response))
            .then(json => ggAlert("Success", "Data berhasil disimpan. Mohon untuk menunggu sampai galeri anda disetujui.", SUCCESS))
            .catch(error => ggAlert("Error", error, ERROR))
        setGalleryLoading(false)
    }

    const uploadMenu = useRef(null)
    const onMenuSelected = (event) => {
        console.log(event.target.files[0])
        let text = "Apakah anda yakin ingin menambahkan Menu?"
        if (window.confirm(text) == true) {
            saveMenu(event.target.files[0])
        } else {
            uploadMenu.current.value = ""
        }
    }

    async function saveMenu(image) {
        setMenuLoading(true)
        var outlet = JSON.parse(localStorage.getItem("outlet"))

        var formData = new FormData()
        
        formData.append("image", image)
        formData.append("idoutlet", outlet.id)

        const requestOptions = {
            method: 'POST',
            body: formData,
            headers: headerNoUrlEncodedBearer
        }
        await fetch(url + "horeca/venue/menuhoreca/store", requestOptions)
            .then(response => getJsonResponse(response))
            .then(json => ggAlert("Success", "Data berhasil disimpan. Mohon untuk menunggu sampai menu anda disetujui.", SUCCESS))
            .catch(error => ggAlert("Error", error, ERROR))
        setMenuLoading(false)
    }

    async function getSchdule() {
        var outlet = JSON.parse(localStorage.getItem("outlet"))
        setScheduleLoading(true)
        await fetch(url + "horeca/scheduletime/" + outlet.id, {headers: headerNoUrlEncodedBearer})
            .then(response => getJsonResponse(response))
            .then(json => {
                setSchedule(json['data'])
            })
            .catch(error => console.log(error))
        setScheduleLoading(false)
    }

    const onScheduleChange = (data, day, type) => {
        if (type === "open" && !scheduleOpenChange.has(day)) {
            scheduleOpenChange.set(day, data)
        }

        if (type === "close" && !scheduleCloseChange.has(day)) {
            scheduleCloseChange.set(day, data)
        }
    }

    const onScheduleChangeButtonClicked = () => {
        var outlet = JSON.parse(localStorage.getItem("outlet"))

        if (scheduleOpenChange.size == 0 && scheduleCloseChange.size == 0) {
            ggAlert("Info", "Anda belum mengubah jadwal.", INFO)
            return
        }

        if (scheduleOpenChange.size != scheduleCloseChange.size) {
            ggAlert("Info", "Mohon lengkapi jam buka dan tutup.", INFO)
            return
        }

        var scheduleArray = []

        for(let [key, value] of scheduleOpenChange) {
            if (!scheduleCloseChange.has(key)) {
                ggAlert("Info", "Mohon lengkapi jam buka dan tutup.", INFO)
                return
            }

            // var to = new Date(value)
            // var tc = new Date(scheduleCloseChange.get(key))

            // var timeOpen = format(to, "HH:mm:ss");
            // var timeClose = format(tc, "HH:mm:ss");

            // console.log(timeOpen)

            // if (timeOpen.getTime() >= timeClose.getTime()) {
            //     alert("Jam buka tidak boleh lebih besar dari pada jam tutup")
            //     return
            // }

            const schedule = {
                id_outlet: outlet.id,
                haribuka: key,
                jambuka: value,
                jamtutup: scheduleCloseChange.get(key)
            }   
            scheduleArray.push(schedule)
        }

        const request = {
            schedules: scheduleArray
        }

        storeSchedule(request)
    }

    async function storeSchedule(request) {
        setScheduleLoading(true)
        const requestOptions = {
            method: 'POST',
            headers: headerBearerJsonRequest,
            body: JSON.stringify(request)
        }

        await fetch(url + "storeschedulehoreca", requestOptions)
            .then(response => getJsonResponse(response))
            .then(json => ggAlert("Success", "Berhasil ubah data schedule", SUCCESS))
            .catch(error => ggAlert("Error", error, ERROR))
        setScheduleLoading(false)
        getSchdule()
    }

    const fileSizeWarning = (key) => {
        ggAlert("Info", "Format ukuran gambar yang disarankan 600x300 px", INFO)
        .then(() => {
            if (key === 1) {
                uploadGallery.current.click()    
            } else {
                uploadMenu.current.click()
            }

        })
    }

    return (
        <div className="home-merchant-container">
            <NavbarDetail
                title="PENGATURAN VENUE"/>
                <Tabs defaultActiveKey="1" onChange={callback}>
                    <TabPane tab="Galeri" key="1">
                        <div className="homeTabPaneContainer">
                            {
                                galleryLoading && <Spin/> 
                                ||
                                gallery.length > 0 && 
                                <GalleryVenue
                                    data={gallery}
                                    onDelete={getGallery}/>
                                ||
                                <EmptyPage
                                    message="Belum ada Foto untuk Galeri Anda!"
                                    subMessage="Masukkan foto-foto interior dan exterior venue Anda"/>
                            }
                        </div>
                        <FaPlus className="gallery-venue-add" onClick={() => fileSizeWarning(1)}/>
                        <input type="file" ref={uploadGallery} name="fileGallery" style={{display: 'none'}} onChange={onGallerySelected}/>
                    </TabPane>
                    <TabPane tab="Menu" key="2">
                        <div className="homeTabPaneContainer">
                            {
                                galleryLoading && <Spin/>
                                ||
                                menu.length > 0 && 
                                <MenuVenue
                                    data={menu}
                                    onDelete={getMenu}/>
                                ||
                                <EmptyPage
                                    message="Belum ada Foto untuk Menu Anda!"
                                    subMessage="Masukkan foto daftar menu Anda"/>
                            }
                        </div>
                        <FaPlus className="gallery-venue-add" onClick={() => fileSizeWarning(2)}/>
                        <input type="file" ref={uploadMenu} name="fileGallery" style={{display: 'none'}} onChange={onMenuSelected}/>
                    </TabPane>
                    <TabPane tab="Jadwal Buka" key="3">
                        <div className="homeTabPaneContainer">
                            {
                                scheduleLoading && <Spin/>
                                ||
                                <Schedule
                                    data={schedule}
                                    onChange={onScheduleChange}
                                    onEditClicked={onScheduleChangeButtonClicked}/>
                            }
                        </div>
                    </TabPane>
                </Tabs>
        </div>
    )
}

export default VenueConfig
