import { Spin, Tabs } from 'antd'
import React, { useEffect, useState } from 'react'
import InboxRowChangePoint from '../../components/inboxrow/InboxRowChangePoint'
import InboxRowReservation from '../../components/inboxrow/InboxRowReservation'
import InboxRowTransaction from '../../components/inboxrow/InboxRowTransaction'
import NavbarSearchTab from '../../components/navbarsearch/NavbarSearchTab'
import { getJsonResponse, headerBearer, headerNoUrlEncodedBearer, url } from '../../service/Service'
import '../inbox/inbox.css'
import moment from 'moment'

const ActivityHistory = () => {

    const { TabPane } = Tabs;
    const [dataTransaction, setDataTransaction] = useState([])
    const [reservations, setReservations] = useState([])
    const [rewards, setReward] = useState([])
    const [searchPlaceHolder, setSearchPlaceholder] = useState("Cari Transaksi")
    const [searchKeyword, setSearhKeyword] = useState(["", "", ""])
    const [tabPosition, setTabPosition] = useState(0)
    const [transactionLoading, setTransactionLoading] = useState(false)
    const [rewardLoading, setRewardLoading] = useState(false)
    const [reservationLoading, setReservationLoading] = useState(false)

    function callback(key) {
        console.log(key);
        setTabPosition(parseInt(key, 10) - 1)
        switch(key) {
            case "1":
                setSearchPlaceholder("Cari Transaksi")
                break;
            case "2":
                setSearchPlaceholder("Cari Tukar Poin")
                break;
            case "3":
                setSearchPlaceholder("Cari Reservasi")
                break;
            default:
                setSearchPlaceholder("Cari Transaksi")
                break;        
        }
    }

    async function getTransaction() {
        setTransactionLoading(true)
        var outlet = JSON.parse(localStorage.getItem("outlet"))

        var endpoint = url + "transactionmerchanthistorys/" + outlet['id']

        if (searchKeyword[0] != "") {
            endpoint = endpoint + "?search=" + searchKeyword[0]
        }

        await fetch(endpoint, {headers: headerNoUrlEncodedBearer})
            .then(response => {
                return getJsonResponse(response)
            })
            .then(json => {
                setDataTransaction([])
                setDataTransaction(json['data'])
            })
            .catch(error => {
                console.log(error)
            })
        setTransactionLoading(false)
    }

    async function getReservation() {
        setReservationLoading(true)
        var outlet = JSON.parse(localStorage.getItem("outlet"))

        var endpoint = url + "horeca/sejarah/reservasi/" + outlet['id'] 

        if (searchKeyword[2] != "") {
            endpoint = endpoint + "?search=" + searchKeyword[2]
        }
        
        await fetch(endpoint, {headers: headerNoUrlEncodedBearer})
            .then(response => {
                return getJsonResponse(response)
            })
            .then(json => {
                setReservations([])
                json['data'].map(function(d, index) {
                    var data = {
                        id: d.id, name: d.user.name, phone: d.user.mobile, time: moment(d.start_program).format("DD MMM yyyy") + " - " + d.jam_reservasi, number: d.jumlah_orang
                    }
                    setReservations(arr => [...arr, data])
                })
            })
            .catch(error => console.log(error))

        setReservationLoading(false)
    }

    useEffect(() => {
        getTransaction()
        getReward()

        if (localStorage.getItem("merchantType") == 1) {
            getReservation()
        }
    }, [])

    async function getReward() {
        setRewardLoading(true)

        var outlet = JSON.parse(localStorage.getItem("outlet"))

        var endpoint = url + "transactionrewarhistorydmerchant/" + outlet['id'] 

        if (searchKeyword[1] != "") {
            endpoint = endpoint + "?search=" + searchKeyword[1]
        }

        await fetch(endpoint, {headers: headerNoUrlEncodedBearer})
            .then(response => {
                return getJsonResponse(response)
            })
            .then(json => {
                console.log(json)
                setReward([])
                json['data'].map(function(d, index){
                    var data = {
                        name: d.user.name, image: d.image, phone: d.user.mobile, package: "", point: 0, item: d.hadiah_name, idtransactionrewards: d.idtransactionrewards, status_transaction: d.status_transaction
                    }
                    setReward(arr => [...arr, data])
                })
            })
            .catch(error => console.log(error))
        setRewardLoading(false)
    }

    const onSearch = (keyword) => {
        var newArray = [...searchKeyword]
        newArray[tabPosition] = keyword
        console.log(newArray)
        setSearhKeyword(newArray)
        switch(tabPosition) {
            case 0:
                getTransaction()
                break;
            case 1:
                getReward()
                break;
            case 2:
                getReservation()
                break;
            default:
                getTransaction()
                break;            
        }
    }

    const onKeywordChanged = (keyword) => {
        var newArray = [...searchKeyword]
        newArray[tabPosition] = keyword
        console.log(newArray)
        setSearhKeyword(newArray)
    }

    return (
        <div className="gg-parent-container">
            <NavbarSearchTab
                placeholder={searchPlaceHolder}
                searchKeyword={searchKeyword[tabPosition]}
                onKeywordChanged={(keyword) => onKeywordChanged(keyword)}
                onSearch={(keyword) => onSearch(keyword)}/>

            <Tabs defaultActiveKey="1" onChange={callback}>
                <TabPane tab="Transaksi" key="1">
                    <div className="homeTabPaneContainer">
                        {
                            transactionLoading && <Spin/>
                            ||
                            dataTransaction.map(function(data, i){
                                return <InboxRowTransaction data={data} isActive={false}/>
                            })
                        }
                    </div>
                </TabPane>
                <TabPane tab="Tukar Poin" key="2">
                   <div className="homeTabPaneContainer">
                        {
                            rewardLoading && <Spin/>
                            ||
                            rewards.map(function(data, i){
                                return <InboxRowChangePoint data={data} isActive={false}/>
                            })
                        }
                    </div>
                </TabPane>
                {
                    localStorage.getItem("merchantType") == 1
                    &&
                    <TabPane tab="Reservasi" key="3">
                        <div className="homeTabPaneContainer">
                            {
                                reservationLoading && <Spin/>
                                ||
                                reservations.map(function(data, i){
                                    return <InboxRowReservation data={data} isActive={false}/>
                                })
                            }
                        </div>
                    </TabPane>
                }
            </Tabs>
        </div>
    )
}

export default ActivityHistory
