import React from "react";
import { FaChevronRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./inbox.css";

const InboxRowChangePoint = ({ data, isActive }) => {
  return (
    <Link
      className="reservation-merchant-row-container"
      to={{
        pathname:
          (isActive && "/scan-qr-reward-merchant") ||
          "/change-point-confirmation",
        state: { data: data, isActive: isActive },
      }}
    >
        {console.log(data)}
      <div className="reservation-merchant-row-container-2">
        <div className="reservation-merchant-row-container-3">
          <p className="reservation-merchant-row-name">{data.name}</p>
          <p className="reservation-merchant-row-phone">{data.phone}</p>
        </div>
        <FaChevronRight className="reservation-merchant-row-icon" />
      </div>
      <div className="reservation-merchant-row-line" />
      <div className="inbox-row-container-2">
        <p className="inbox-row-change-point-name">{data.item}</p>
        {data.status_transaction === 1 ? (
          <p className="inbox-row-pakcage-point">Diterima</p>
        ) : data["status_transaction"] === 2 ? (
          <p className="inbox-row-pakcage-reject">Ditolak</p>
        ) : (
          <></>
        )}
      </div>
    </Link>
  );
};

InboxRowChangePoint.defaultProps = {
  isActive: true,
};

export default InboxRowChangePoint;
