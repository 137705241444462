import React, { useState } from "react";
import { FaStar } from "react-icons/fa";
import ButtonPrimary from "../../components/ButtonPrimary";
import NavbarDetail from "../../components/navbardetail/NavbarDetail";
import UserCard from "../../components/usercard/UserCard";
import {
  ERROR,
  getJsonResponse,
  ggAlert,
  headerNoUrlEncodedBearer,
  url,
} from "../../service/Service";
import "./transaction-confirmation.css";
import { isSafari } from "react-device-detect";
import { useHistory } from "react-router-dom";
import { Spin } from "antd";
import SuccessLayout from "../../components/successlayout/SuccessLayout";
import CancelLayout from "../../components/successlayout/CancelLayout";

const TransactionConfirmation = (props) => {
  const { state } = props.location;

  const history = useHistory();
  const [confirm, setConfirm] = useState(false);
  const [reject, setReject] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonText, setButtonText] = useState("Konfirmasi Pembelian");
  const [buttonTextReject, setButtonTextReject] = useState("Tolak Pembelian");
  const onConfirm = () => {
    if (confirm) {
      if (isSafari) {
        history.go(-1);
      } else {
        history.goBack();
      }
      return;
    }
    confirmAPI();
  };
  const onReject = async() => {
    if (reject) {
      if (isSafari) {
        history.go(-1);
      } else {
        history.goBack();
      }
      return;
    }
    var formData = new FormData();
    formData.append("idpaket", state.data["idpaket"]);
    formData.append("qty", state.data["jumlahpembelian"]);
    formData.append("id_konsumen", state.data["idkonsumen"]);
    formData.append("idTransaction", state.data["idTransaction"]);
    formData.append("tipe", state.data["tipe"]);
    formData.append("reject", true);
    const requestOptions = {
      method: "POST",
      body: formData,
      headers: headerNoUrlEncodedBearer,
    };

    // transactionmerchant/orderpaketcpo

    await fetch(
      url + "transactionmerchant/konfirmasiorderpaket",
      requestOptions
    )
      .then((response) => {
        return getJsonResponse(response);
      })
      .then((json) => {
        console.log(json);
        setButtonTextReject("Kembali ke Inbox")
        setReject(true);
      })
      .catch((error) => {
        ggAlert("Error", error, ERROR);
      });

  };
  async function confirmAPI() {
    setLoading(true);
    if (!state) {
      setLoading(false);
      return;
    }

    var formData = new FormData();
    formData.append("idpaket", state.data["idpaket"]);
    formData.append("qty", state.data["jumlahpembelian"]);
    formData.append("id_konsumen", state.data["idkonsumen"]);
    formData.append("idTransaction", state.data["idTransaction"]);
    formData.append("tipe", state.data["tipe"]);
    formData.append("reject", false);
    const requestOptions = {
      method: "POST",
      body: formData,
      headers: headerNoUrlEncodedBearer,
    };

    // transactionmerchant/orderpaketcpo

    await fetch(
      url + "transactionmerchant/konfirmasiorderpaket",
      requestOptions
    )
      .then((response) => {
        return getJsonResponse(response);
      })
      .then((json) => {
        console.log(json);
        setConfirm(true);
        setButtonText("Kembali ke Inbox");
      })
      .catch((error) => {
        ggAlert("Error", error, ERROR);
      });
    setLoading(false);
  }

  return (
    <div className="gg-parent-container">
      <NavbarDetail title="RINCIAN TRANSAKSI" />

      <div className="gg-content-container">
        {(confirm && (
          <SuccessLayout
            title="Terima kasih"
            message="Pembalian paket telah berhasil"
          />
        )) ||
          (reject && (
            <CancelLayout
              title="Terima kasih"
              message="Reject paket telah berhasil"
            />
          )) || (
            <>
              <UserCard data={state && state.data} />

              <p className="reservation-confirmation-label">
                {state && state.data && state.data["namapaket"]}
              </p>

              {state && state.type !== "cpo" && (
                <div className="transaction-confirmation-category-container">
                  <FaStar className="transaction-confirmation-icon" />
                  <p className="transaction-confirmation-category-label">
                    Loyalty
                  </p>
                </div>
              )}
              {/* <p className="transaction-confirmation-package-label">Paket C</p> */}
              <p className="transaction-confirmation-number-label">
                {state && state.data && state.data["jumlahpembelian"]} pcs
              </p>

              {state && state.type !== "cpo" && (
                <p className="transaction-confirmation-point-label">
                  {(state && state.data && state.data["point"]) || 0} poin
                </p>
              )}
            </>
          )}
      </div>

      {state && state.isActive && (
        <div className="gg-bottom-container">
          {(loading && <Spin />) || (
            <>
              {reject === false ? (
                <ButtonPrimary
                  text={buttonText}
                  onClick={() => onConfirm()}
                  width={confirm === false ? "50%" : "100%"}
                />
              ) : (
                <ButtonPrimary
                  text={buttonTextReject}
                  onClick={() => onReject()}
                  width={"100%"}
                />
              )}

              {confirm === false && reject === false ? (
                <ButtonPrimary
                  text={buttonTextReject}
                  onClick={() => onReject()}
                  width={"50%"}
                  marginLeft={"5%"}
                />
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default TransactionConfirmation;
