/* eslint-disable react-hooks/exhaustive-deps */
import { Pagination, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { FaChevronRight, FaLocationArrow, FaMapMarked, FaMapMarkedAlt, FaMapMarker, FaSearchLocation } from 'react-icons/fa'
import EmptyPage from '../../components/emptypage/EmptyPage'
import NavbarSearch from '../../components/navbarsearch/NavbarSearch'
import OutletModal from '../../components/outletmodal/OutletModal'
import StoreList from '../../components/storelist/StoreList'
import { getJsonResponse, ggAlert, headerBearer, headerNoUrlEncodedBearer, INFO, url } from '../../service/Service'
import './grocery-store-list.css'
import useGeolocation from '../../util/useGeolocation'

const GroceryStoreList = () => {

    const [items, setItems] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPage, setTotalPage] = useState(0)
    const [loading, setLoading] = useState(false)
    const [currentLocation, setCurrentLocation] = useState({})
    const [isShowCurrentLocationModal, setShowCurrentLocationModal] = useState(false)
    const [cities, setCities] = useState([])
    const [citiesMap, setCitiesMap] = useState(new Map())
    const location = useGeolocation()

    const onCurrentLocationCancel = () => {
        setShowCurrentLocationModal(!isShowCurrentLocationModal)
    }

    const onCurrentLocationOk = (items) => {
        console.log(citiesMap)
        setCurrentLocation({name: items, id: citiesMap.get(items)})
        setCurrentPage(1)
        getOutlet(currentPage, citiesMap.get(items))
        setShowCurrentLocationModal(!isShowCurrentLocationModal)
    }

    const showCurrentLocationModal = () => {
        setShowCurrentLocationModal(!isShowCurrentLocationModal)
    }

    async function getOutlet(page, id) {
        setLoading(true)
        await fetch(url + "Outlet/Warung?page=" + page + "&idcity=" + id + "&latitude=&longitude=", {headers: headerBearer})
            .then(response => {
                return getJsonResponse(response)
            })
            .then(json => {
                setItems(json['data']['data'])
                setTotalPage(json['data']['last_page'] * 10)
            })
            .catch(error => console.log(error))
        setLoading(false)
    }

    async function getOutletCity() {
        await fetch(url + "operatingunitoutlet", {headers: headerNoUrlEncodedBearer})
            .then(response => getJsonResponse(response))
            .then(json => {
                setCities([])

                var cm = new Map()

                json.data.map(function(d, i){
                    setCities(arr => [...arr, d.name])
                    if (!cm.has(d.name)) {
                        cm.set(d.name, d.id)
                    }
                })

                setCitiesMap(cm)

                if (location.loaded && location.coordinates && !currentLocation.id) {
                    getOutlet(currentPage, "")
                    ggAlert("Info", "Daftar restoran/cafe berdasarkan lokasi pada GPS anda", INFO)
                } else {
                    // getOutlet(currentPage, json['data'][0]['id'])
                    // setCurrentLocation(json['data'][0])
                    // alert("Daftar restoran/cafe ini berada di " + json['data'][0]['name'])
                    getOutlet(currentPage, localStorage.getItem("lokasiId"))
                    setCurrentLocation({name: localStorage.getItem("kota"), id: localStorage.getItem("lokasiId")})
                    ggAlert("Info", "Daftar restoran/cafe ini berada di " + localStorage.getItem("kota"), INFO)
                }
            })
            .catch(error => console.log(error))
    }

    async function searchOutlet(search) {
        if (search === "") {
            if (location.loaded && location.coordinates && !currentLocation.id) {
                getOutlet(currentPage, "")    
            } else {
                getOutlet(currentPage, currentLocation.id)
            }
            return
        }
        setLoading(true)
        await fetch(url + "Outlet/search/"+ search +"/Warung", {headers: headerNoUrlEncodedBearer})
            .then(response => getJsonResponse(response))
            .then(json => {
                setItems(json['data']['data'])
                setTotalPage(json['data']['last_page'] * 10)
            })
            .catch(error => console.log(error))
        setLoading(false)
    }

    const onChange = page => {
        setCurrentPage(page)
        getOutlet(page, currentLocation.id)
      }

    useEffect(() => {
        getOutletCity()
    }, [])

    return (
        <div className="grocery-store-list-container">
            <NavbarSearch placeholder="Cari Warung/Toko..."
                onSearch={(e) => searchOutlet(e)}/>
            <div className="grocery-store-list-location-container">
                <p className="grocery-store-list-location-label">Lokasi Sekarang</p>
                <FaMapMarker className="grocery-store-list-location-icon"/>
                <p className="grocery-store-list-location" onClick={() => showCurrentLocationModal()}>{currentLocation.name && currentLocation.name || "Pilih Lokasi"}</p>
                <FaChevronRight className="grocery-store-list-location-icon-2"/> 
            </div>

            <div className="grocery-store-list-container-2">
                {
                    loading && <Spin/>
                    ||
                    !loading && items.length == 0 && <EmptyPage message="Tidak ada data warung"/> ||
                    <StoreList
                        items={items}/>}
            </div>
            <div className="restaurant-list-pagination-container">
                <Pagination
                    current={currentPage} 
                    onChange={onChange} 
                    total={totalPage} />
            </div>

            <OutletModal
                    isShowModal={isShowCurrentLocationModal}
                    onCancel={() => onCurrentLocationCancel()}
                    selectedItem={new Map()}
                    city={cities}
                    onOk={(e) => onCurrentLocationOk(e)}/>
        </div>
    )
}

export default GroceryStoreList
