const ButtonPrimary = ({ text, onClick, active, width , marginLeft}) => {
    return (
        <button
            style={{
                background: active && '#ECD898' || 'gray',
                borderRadius: 10,
                color: active && 'black' || 'white',
                width: width || '100%',
                border: 0,
                height: 40,
                fontSize: 16,
                cursor: 'pointer',
                marginLeft: marginLeft
            }}
            onClick={() => onClick != null && onClick()}>
                {text}
        </button>
    )
}

ButtonPrimary.defaultProps = {
    active: true,
    onClick: null 
}

export default ButtonPrimary
